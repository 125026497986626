<template>
  <div class="headerTit">
      <a-button type="link" @click="backFn">
        <LeftOutlined />返回
      </a-button><span class="separate">|</span>{{route.query.id?'编辑内容':'新增内容'}}
    </div>
  <div>
    <Form :key="nowTime" :formState="formState" :rules="rules" :modelList="modelList" @handleSubmit="handleSubmit"
      @handleCancel="handleCancel" @uploadChange="uploadChange">
      <template #exampleContext>
        <TEditor ref="editor" :value="formState.exampleContext" @input="changeContent" />
      </template>
    </Form>
  </div>
</template>
<script setup>
import Form from '../../components/Form'
import { reactive,toRefs,getCurrentInstance} from 'vue'
import {  message } from 'ant-design-vue';
import { useRouter,useRoute } from 'vue-router';
import {getText,isNull} from '@/utils/space.js';
import TEditor from '../../components/TEditor.vue';
const router = useRouter()
const  route = useRoute()
const { proxy } = getCurrentInstance()
  let $api = proxy.$api
  
const state = reactive({
  nowTime: '',
  formState:{
    exampleName:'',
    exampleType:null,
    priority:'',
    exampleContext:'',
    exampleUrl:'',
    status:1,
    fileIds:[]
  },
  rules:{},
  modelList:[
  {
        label: "案例名称",
        ruleName: "exampleName",
        maxLength:20,
        // isShowCount: true,
        type: "input",
        placeholder:'请输入',
      },
      {
        label: "案例分类",
        ruleName: "exampleType",
        type: "select",
        placeholder:'请选择',
        options: []
      },
      {
        label: "优先级",
        ruleName: "priority",
        maxLength:20,
        type: "input",
        placeholder:'请输入数字',
      },
      {
        label: "案例描述",
        ruleName: "exampleContext",
        type: "slot",
        placeholder:'请输入案例描述',
      },
      {
        label: "案例链接",
        ruleName: "exampleUrl",
        type: "input",
        placeholder:'请输入链接地址，必须以http或https开头',
      },
      {
        label: "启用状态",
        ruleName: "status",
        type: "switch",
      },
      {
        label: "案例图片",
        ruleName: "fileVOS",
        type: "upload",
      },
  ]
})
const {formState,rules,modelList,nowTime}=toRefs(state)

if(route.query.id){
  $api.getCaseInfo({id:route.query.id}).then(res=>{
    if(res.code == 200){
      console.log(res,'xiagnqing')
      let {exampleName,exampleType,priority,exampleContext,exampleUrl,status,fileVOS,id}=res.data;
      // console.log(exampleName,exampleType,priority,exampleContext,exampleUrl,status)
      state.formState = Object.assign({},{exampleName,exampleType,priority,exampleContext,exampleUrl,status,fileVOS,id});
      state.formState.fileIds = fileVOS.map(item=>{
        return item.id
      })
      state.nowTime = Date.now();
    }
  })
}

// 富文本
const changeContent = (v) => {
  state.formState.exampleContext = v;
  if(v){
    let text = isNull(getText(v));
    if(text){
      state.formState.exampleContext = ''
    }
  }
}

const getDictionaryByCode = ()=>{
  $api.getDictionaryByCode({code:'EXAMPLE_TYPE'}).then(res=>{
    console.log(res,'字典')
    if(res.code == 200){
      state.modelList[1].options = res.data.map(item=>{
        return{
          optionLabel: item.dicvalue,
          optionValue: item.dickey,
        }
      })
    }
  })
}
getDictionaryByCode()

const uploadChange = (data)=>{
  let list = JSON.parse(JSON.stringify(data))
  console.log(list,'data?????')
  state.formState.fileIds = list.map(item=>{
    return item.response?item.response.data.id:item.id
  })
  state.formState.fileVOS = list.map(item=>{
    if(item.response){
      return{
        id:item.response.data.id,
        uid:item.response.data.id,
        url:item.response.data.url,
        name:item.response.data.fileName
      }
    }else{
      return item
    }
  })
}

const handleSubmit = ()=>{
  console.log(state.formState)
  // return;
  $api.updateCase(state.formState).then(res=>{
    if(res.code == 200){
      message.success('保存成功');
      router.back()
    }else{
      message.error(res.msg)
    }
  })
}
const handleCancel = ()=>{
  router.back()
}
const backFn = () => {
    router.back()
  }
</script>
<style lang="less" scoped>
.headerTit {
    background:#f5f5f5;
    padding:10px 0 8px;
    .ant-btn-link{
      padding-left:0;
    }
    .separate {
      padding: 0 15px 0 0;
      color: #999;
    }
  }
</style>