<template>
  <div class="tinymce-box">
      <Editor v-model="contentValue" :init="init" :disabled="disabled" @onClick="onClick" />
  </div>
</template>

<script setup>
import{ ref,watch,onMounted} from 'vue'
// import {uploadSingleFile} from '@/api/upload.js'
//引入tinymce编辑器
import Editor from '@tinymce/tinymce-vue'

//引入node_modules里的tinymce相关文件文件
import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入则不显示编辑器
import 'tinymce/themes/silver'  //编辑器主题，不引入则报错
import 'tinymce/icons/default'  //引入编辑器图标icon，不引入则不显示对应图标

// 引入编辑器插件（基本免费插件都在这儿了）
import 'tinymce/models/dom'
import 'tinymce/plugins/advlist'  //高级列表
import 'tinymce/plugins/anchor'  //锚点
import 'tinymce/plugins/autolink'  //自动链接
import 'tinymce/plugins/autoresize'  //编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import 'tinymce/plugins/autosave'  //自动存稿
import 'tinymce/plugins/charmap'  //特殊字符
import 'tinymce/plugins/code'  //编辑源码
import 'tinymce/plugins/codesample'  //代码示例
import 'tinymce/plugins/directionality'  //文字方向
import 'tinymce/plugins/emoticons'  //表情
import 'tinymce/plugins/fullscreen'  //全屏
import 'tinymce/plugins/help'  //帮助
import 'tinymce/plugins/image'  //插入编辑图片
import 'tinymce/plugins/importcss'  //引入css
import 'tinymce/plugins/insertdatetime'  //插入日期时间
import 'tinymce/plugins/link'  //超链接
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/media' //插入编辑媒体
import 'tinymce/plugins/nonbreaking' //插入不间断空格
import 'tinymce/plugins/pagebreak' //插入分页符
import 'tinymce/plugins/preview'//预览
// import 'tinymce/plugins/quickbars'  //快速工具栏
import 'tinymce/plugins/save'  //保存
import 'tinymce/plugins/searchreplace'  //查找替换
import 'tinymce/plugins/table'  //表格
// import 'tinymce/plugins/template'  //内容模板
import 'tinymce/plugins/visualblocks'  //显示元素范围
import 'tinymce/plugins/visualchars'  //显示不可见字符
import 'tinymce/plugins/wordcount'  //字数统计
import '../../public/tinymce/plugins/lineheight/plugin'; // 行高
import axios from "axios"
const props = defineProps({
  value: {
          type: String,
          default: ''
  },
  disabled: {
          type: Boolean,
          default: false
  },
  plugins: {
          type: [String, Array],
          default: "lists image link fullscreen anchor preview autolink lineheight"
  },
  toolbar: {
          type: [String, Array],
          default: 'bold italic underline strikethrough | undo redo | blocks fontfamily fontsize autolink | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote lineheight | link unlink image code'
  },
})
const init = ref({
  promotion: false,
  language_url: '/tinymce/langs/zh-Hans.js',  //引入语言包文件
  language: 'zh-Hans',  //语言类型
  directionality : 'ltr',
  skin_url: '/tinymce/skins/ui/oxide',  //皮肤：浅色
  // skin_url: '/tinymce/skins/ui/oxide-dark',//皮肤：暗色
  content_css: '/tinymce/skins/ui/oxide/content.css',
  plugins: props.plugins,  //插件配置
  toolbar: props.toolbar,  //工具栏配置，设为false则隐藏
  menubar: false,  //菜单栏配置，设为false则隐藏，则默认显示全部菜单不配置，也可自定义配置--查看 http://tinymce.ax-z.cn/configure/editor-appearance.php --搜索“自定义菜单”

  // fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px',  //字体大小
  font_family_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Arial='Arial';",  //字体样式
  // lineheight_formats: "0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5",  //行高配置，也可配置成"12px 14px 16px 20px"这种形式

  height: 300,  //注：引入autoresize插件时，此属性失效
  placeholder: '在这里输入正文内容',
  branding: false,  //tiny技术支持信息是否显示
  resize: false,  //编辑器宽高是否可变，false-否,true-高可变，'both'-宽高均可，注意引号
  statusbar: false,  //最下方的元素路径和字数统计那一栏是否显示
  elementpath: false,  //元素路径是否显示

  content_style: "img {max-width:100%;}",  //直接自定义可编辑区域的css样式
  // content_css: '/tinycontent.css',  //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入

  // images_upload_url: '/apib/api-upload/uploadimg',  //后端处理程序的url，建议直接自定义上传函数image_upload_handler，这个就可以不用了
  // images_upload_base_path: '/demo',  //相对基本路径--关于图片上传建议查看--http://tinymce.ax-z.cn/general/upload-images.php
  paste_data_images: true,  //图片是否可粘贴
  images_upload_handler: (blobInfo) => new Promise((resolve, reject) =>{
    let params=new FormData()
    params.append('file',blobInfo.blob())
    let config = {
        headers:{
        Authorization:sessionStorage.getItem('token')?sessionStorage.getItem('token'):''
      }
    }
    axios.post('/rd_boot_web/sys/oss/file/upload',params,config).then(res=>{
      console.log(res,'res上传')
      if(res.status==200){
        resolve(res.data.data.url)
      }else{
        reject("上传失败")
      }
    }).catch(()=>{
      reject("上传出错，服务器开小差了呢")
    })
  }),
  file_picker_callback: (callback, value, meta) => {
      //文件分类
      var filetype='.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
      //为不同插件指定文件类型
      switch(meta.filetype){
          case 'image':
              filetype='.jpg, .jpeg, .png, .gif';
              break;
          case 'media':
              filetype='.mp3, .mp4';
              break;
          case 'file':
          default:
      }
      //模拟出一个input用于添加本地文件
      var input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', filetype);
      input.click();
      input.onchange = function() {
          var file = this.files[0];
          let params=new FormData()
          params.append('file',file)
          // uploadSingleFile(params).then(res=>{
          //     if(res.code==200){
          //         callback(res.data.url, {text: '文件'});
          //     }
          // }).catch(()=>{
          //     failure("上传出错，服务器开小差了呢")
          // })
      }
  },
  setup: function(editor) {
    editor.on('init', function(ed) {
      ed.target.editorCommands.execCommand("fontName", false, "微软雅黑");
    });                       
  },
})
const contentValue = ref()
const emits = defineEmits(['input','onClick'])
watch(()=>props.value,(newVal,oldVal)=>{
  contentValue.value = newVal
},{
  deep:true,
  immediate:true
})
watch(()=>contentValue.value,(newContentVal,oldContentVal)=>{
  emits('input', newContentVal)
},{
  deep:true,
  immediate:true
})
onMounted(()=>{
  tinymce.init({})
})
const onClick =(e)=>{
  emits('onClick', e, tinymce)
}
//清空内容
const clear =()=>{
  contentValue.value = ''
}
</script>

<style>
.tox:not([dir=rtl]) .tox-form__controls-h-stack>:not(:first-child){
  display: none;
}
</style>