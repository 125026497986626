<template>
  <a-upload 
      :headers="headers"
      :file-list="fileList"
      action="/rd_boot_web/sys/oss/file/upload"
      list-type="picture-card"
      @preview="handlePreview"
      @change="handleChange"
    >
      <div v-if="fileList.length < maxFiles">
        <plus-outlined />
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
</template>
<script setup>
import { PlusOutlined } from '@ant-design/icons-vue';
import {ref,reactive,toRefs,defineProps,defineEmits,onMounted} from 'vue'

let headers = {
  Authorization:sessionStorage.getItem('token')?sessionStorage.getItem('token'):''
}
const $emit = defineEmits(['uploadChange'])
const props = defineProps({
  uploadParams: {
    type: Object,
    default: null
  },
  list:{
    type: Array,
    default: null
  }
})
const maxFiles = ref(9);
// const state = reactive({
//   maxFiles:1
// })
// const {maxFiles} = toRefs(state)
const fileList = ref([]);
const previewVisible = ref(false)
const previewImage = ref('')



onMounted(()=>{
  // maxFiles.value = props.uploadParams.maxFiles
  let tempList = [];
  props.list&&props.list.length&&JSON.parse(JSON.stringify(props.list)).forEach(item=>{
    tempList.push({
      id:item.id,
      uid:item.id,
      url:item.url,
      name:item.fileName
    })
  })
  fileList.value = tempList
})

const handleChange = (info)=>{
  console.log(info,'info===')
  let fileLists = [...info.fileList];
  fileList.value = [...info.fileList];
  if (info.file.status === "done") {
    previewImage.value = info.file.response.data.url;
    console.log(fileLists,'fileList.value')
    $emit("uploadChange",fileLists)
  }
  if (info.file.status === "removed") {
    $emit("uploadChange",fileLists)
  }

}
const handlePreview = (file)=>{
  console.log(file.response,'预览',file)
  previewVisible.value = true;
  previewImage.value = file.response?file.response.data.url:file.url;
}
const handleCancel = ()=>{
  previewVisible.value = false
}
</script>