<template>
  <div class="formWrapper">
    <a-form ref="formRef" :model="formState" :rules="rules" @finish="onSubmit">
      <a-row>
        <a-col v-for="(item, index) in modelList" :key="index" :span="24">
          <a-form-item v-if="item.label && item.type == 'input'" :name="item.ruleName" :label="item.label">
            <a-input v-model:value="formState[item.ruleName]" :placeholder="item.placeholder" :maxlength="item.maxLength"
            :show-count="item.isShowCount" allow-clear @change="inputChange($event,item.ruleName)" />
          </a-form-item>
          <a-form-item v-if="item.label && item.type == 'textarea'" :name="item.ruleName" :label="item.label">
            <a-textarea v-model:value="formState[item.ruleName]" :auto-size="{ minRows: 4, maxRows: 5 }"
            :maxlength="item.maxLength" :show-count="item.isShowCount" :placeholder="item.placeholder" allow-clear @change="inputChange($event,item.ruleName)" />
          </a-form-item>
          <a-form-item v-if="item.label && item.type == 'select'" :name="item.ruleName" :label="item.label">
            <a-select v-model:value="formState[item.ruleName]" :placeholder="item.placeholder" :disabled="item.disabled">
              <a-select-option v-for="(val, index) in item.options" :key="index"
                :value="val.optionValue">{{val.optionLabel}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="radio-form-item" v-if="item.label && item.type == 'radio'" :name="item.ruleName" :label="item.label">
            <a-radio-group v-model:value="formState[item.ruleName]" :disabled="item.disabled">
              <a-radio v-for="(val, index) in item.options" :key="index" :value="val.key">{{val.value}}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="item.label && item.type == 'switch'" :name="item.ruleName" :label="item.label">
             <a-switch :checked="formState[item.ruleName]=='1'?true:false" @change="switchChange" />
          </a-form-item>
          <a-form-item v-if="item.label && item.type == 'upload'" :name="item.ruleName" :label="item.label">
             <Upload @uploadChange="uploadChange" :list="formState[item.ruleName]" />
          </a-form-item>
          <a-form-item v-if="item.label && item.type == 'slot'" :name="item.ruleName" :label="item.label">
            <slot :name="item.ruleName" :index="item.index"></slot>
          </a-form-item>
          <!-- <a-form-item v-if="item.label && item.type == 'upload'" :name="item.ruleName" :label="item.label">
            <Upload></Upload>
          </a-form-item>
          <a-form-item v-if="item.label && item.type == 'videoUpload'" :name="item.ruleName" :label="item.label">
            <VideoUpload></VideoUpload>
          </a-form-item> -->
        </a-col>
      </a-row>
      <a-form-item :wrapper-col="{ span: 14, offset: 3 }" style="padding-bottom:20px;">
        <a-config-provider :autoInsertSpaceInButton="false">
          <a-button style="margin-right: 10px" @click="onCancel">取消</a-button>
          <a-button type="primary" @click.prevent="onSubmit">保存</a-button>
        </a-config-provider>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup>
import Upload from "./upload";
  import { Form } from 'ant-design-vue';
  import { ref, toRefs, reactive, defineProps, defineEmits, onMounted } from 'vue'
  const useForm = Form.useForm;

  // const rulesRef = reactive({
  //   name: [
  //     {
  //       required: true,
  //       message: '请输入',
  //     },
  //   ],

  // });
  const formRef = ref();
  const state = reactive({
    formState: {},
    modelList: [],
    rules:{}
  })
  //返回模板中需要的参数
  const {
    formState,
    modelList,
    rules
  } = toRefs(state)

  const layout = {
    labelCol: {
      span: 2,
    },
  };
  const $emit = defineEmits(['handleSubmit','switchChange','uploadChange'])
  const props = defineProps({
    formState: {
      type: Object,
      default: null
    },
    modelList: {
      type: Array,
      default: null
    },
    rules: {
      type: Object,
      default: null
    }
  })

  // watch(() =>modelList,(newVal, oldVal)=>{
  //   console.log('newVal==监听modelList',newVal);
  // },{deep: true})
  console.log(props.formState,'===???')

  const switchChange = (checked,event)=>{
    console.log(checked,event)
    state.formState.status = checked?"1":"0"
  }

  const  uploadChange = (data)=>{
    $emit("uploadChange",data)
  }

  const onCancel = () => {
    $emit("handleCancel")
  };
  onMounted(() => {
    console.log('执行没有');
    state.modelList = props.modelList;
    state.formState = props.formState;
    state.rules = props.rules;


  })
  const onSubmit = async () => {
    const values = await formRef.value.validateFields();
    console.log(values);
    $emit("handleSubmit", values)
  };

  const inputChange = (e,name)=>{
    state.formState[name] = state.formState[name].replace(/\s+/g,'')
  }
</script>
<style lang="less">
.radio-form-item{
  margin-bottom: 12px;
}
</style>
<style scoped lang="less">
  .formWrapper {
    margin: 30px 0 0 0px;
    width: 800px;
    /deep/.ant-upload.ant-upload-select-picture-card,/deep/.ant-upload-list-picture-card-container{
      width: 60px;
      height: 60px;
      margin:0 8px 0 0;
      background-color:#fff
    }
    /deep/div.ant-upload-list-item-thumbnail{
      line-height:normal;
      margin-top:20px;
    }
  }
  /deep/.ant-form-horizontal .ant-form-item-label{
    width:100px !important;
  }
</style>